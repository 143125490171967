




















import { ApiGetCurriculumDto, ApiHierarchicalAreaDtoType, ApiMetadataDtoInfoType } from "@/api/generated/Api";
import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import BaseSheetField from "@/components/shared/sheet/BaseSheetField.vue";
import BaseSheetHeader from "@/components/shared/sheet/BaseSheetHeader.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { getNusCodeNameByCode, isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { loadingRef } from "@/shared/helpers/refHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, Ref, ref, watch } from "@vue/composition-api";
import { Store } from "vuex";

export default defineComponent({
  name: "CurriculumDetailsCard",
  components: { BaseSheet, BaseSheetHeader, BaseSheetField },
  props: {
    curriculum: {
      type: Object,
      required: true,
    },
    courseAreas: {
      type: Array,
    },
  },
  setup(props) {
    const store = useStore<StoreState>();
    const isLoading = loadingRef(true);
    const curriculumValues = useAutoDeepCloneProp<ApiGetCurriculumDto>(props, "curriculum");
    const generalInfoCurriculumValues = ref<{ value: string; label: string }[]>();
    const curriculumDefaultOptions = computed(() => store.state.plans.defaultOptions);

    watch(
      curriculumDefaultOptions,
      () => {
        setGeneralInfoCurriculumValues(
          store,
          isLoading,
          curriculumValues,
          generalInfoCurriculumValues,
          props.courseAreas as any[]
        );
      },
      { immediate: true }
    );

    return {
      generalInfoCurriculumValues,
    };
  },
});

const getNameFromDefaultOptionList = (id: number, metaDataInputList?: ApiMetadataDtoInfoType[] | null) =>
  metaDataInputList?.find((metaData: ApiMetadataDtoInfoType) => {
    if (metaData.id === id && metaData.description) {
      return metaData.description;
    }
  });

const setGeneralInfoCurriculumValues = async (
  store: Store<StoreState>,
  isLoading: Ref<boolean>,
  curriculumValues: Ref<ApiGetCurriculumDto>,
  generalInfoCurriculumValues: Ref,
  courseAreas: any[]
) => {
  isLoading.value = true;

  const memberOrganizationName = curriculumValues.value.orgId; //  TODO - update when api ready task item 10721

  const mainCourse: ApiHierarchicalAreaDtoType =
    courseAreas &&
    courseAreas.find((courseArea) =>
      courseArea.children?.some((child: ApiHierarchicalAreaDtoType) => child.id === curriculumValues.value?.subCourseId)
    );
  const mainCourseName = mainCourse?.description;
  const courseName =
    mainCourse?.children && mainCourse?.children.find((x) => x.id === curriculumValues.value.subCourseId)?.description;

  // Find code name (drafts don't return nusCodeName (uses same dto as edit/upser -.-))
  const nusCodeName = await getNusCodeNameByCode(curriculumValues.value.nusCode);

  generalInfoCurriculumValues.value = [
    {
      value: memberOrganizationName,
      label: "Medlemsorganisasjon.",
    },
    {
      value: mainCourseName,
      label: "Hovedområde",
    },
    {
      value: courseName,
      label: "Kursområde",
    },
    {
      value: curriculumValues.value.teachingMethod,
      label: "Undervisningsform",
    },
    {
      value: curriculumValues.value.ssbCode,
      label: "SSB-kode",
    },
    {
      value: curriculumValues.value.cefrLevel,
      label: "CEFR-kode",
    },
    {
      value: curriculumValues.value.externalCurriculumUrl,
      label: "Lenke til ekstern studieplan",
    },
    {
      value: curriculumValues.value.vocationalSchool?.subjectCount,
      label: "Antall emner",
    },
    {
      value: curriculumValues.value.vocationalSchool?.practice,
      label: "Praksis",
    },
    {
      value: curriculumValues.value.hoursMin,
      label: "Timer minimum",
    },
    {
      value: curriculumValues.value.hoursMax,
      label: "Timer maksimum",
    },
    {
      value: curriculumValues.value.hoursOnline,
      label: "Timer online",
    },
    {
      value: curriculumValues.value.hoursStudyLoad,
      label: "Total studiebelastning",
    },
    {
      value: getNameFromDefaultOptionList(
        curriculumValues.value.levelId,
        store.state.plans.defaultOptions.courseLevels
      ),
      label: "Nivå",
    },
    {
      value: getNameFromDefaultOptionList(
        curriculumValues.value.evaluationId,
        store.state.plans.defaultOptions.courseEvaluations
      ),
      label: "Evalueringskode",
    },
    {
      label: "Kurset skal ha en lærer",
      value: curriculumValues.value.teacherRequired,
    },
    {
      label: "Krav til forkunnskaper",
      value: curriculumValues.value.foreknowledgeRequired,
    },
    {
      label: "Krav om kursbevis eller vitnemål",
      value: curriculumValues.value.courseCertificateRequired,
    },
  ];

  if (isVocationalSchool(mainCourse?.id)) {
    generalInfoCurriculumValues.value = generalInfoCurriculumValues.value.concat([
      {
        label: "NUS-kode",
        value: curriculumValues.value.nusCode ? `${curriculumValues.value.nusCode} - ${nusCodeName}` : undefined,
      },
    ]);
  }

  generalInfoCurriculumValues.value.filter((item: any) => item.value !== undefined);
  isLoading.value = false;
};
