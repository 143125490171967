<template>
  <BaseSheet :outlined="false" rounded="lg">
    <BaseSheetHeader title="Filer" />
    <!-- sending curriculum to curriculumId is probably wrong TODO fix -->
    <BaseFileList v-if="curriculum" entity="curriculum" :curriculumId="curriculum" />
  </BaseSheet>
</template>

<script>
import BaseFileList from "@/components/shared/BaseFileList.vue";
import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import BaseSheetHeader from "@/components/shared/sheet/BaseSheetHeader.vue";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "CurriculumFiles",
  components: { BaseSheet, BaseSheetHeader, BaseFileList },
  props: {
    curriculum: {
      type: Object,
      required: true,
    },
    curriculumFileList: {
      type: Array,
      required: true,
    },
  },
});
</script>
