





























import { mapGetters } from "vuex";
import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "LightboxContentArea",
  components: { BaseSheet },
  data: () => ({
    overlay: false,
    downloadFile: null,
  }),
  computed: {
    ...mapGetters("files", ["getFile"]),
  },
  mounted() {
    // Close modal with 'esc' key
    // TODO this need unsub!
    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 27) {
        this.closeOverlay();
      }
    });
  },
  methods: {
    closeOverlay() {
      this.overlay = !this.overlay;
      this.$store.commit("files/SET_FILE_URL", null);
    },
  },
});
