





















import { api } from "@/api/api";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";

const initialData = () => ({
  search: "",
});

const headers = [
  { text: "Kurs-ID", value: "id" },
  { text: "Kurs navn", value: "courseName" },
  {
    text: "Organisasjon",
    value: "ownerOrganizationName",
  },
  {
    text: "Fagansvarlig",
    value: "courseSupervisorId",
  },
  {
    text: "Status",
    value: "status",
  },
  { text: "Handlinger", value: "actions", sortable: false },
];

export default defineComponent({
  name: "CourseList",
  components: { BaseTableFiltered },
  setup() {
    const store = useStore<StoreState>();
    const route = useRoute();
    const search = ref("");
    const connectedCourses = ref();

    onMounted(async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        try {
          const courses = await api.curriculum.getCoursesByCurriculumIdAsync(+route.params.id);
          connectedCourses.value = courses.data;
        } catch {
          openNotification(store, NotificationItemType.Error, "Det skjedde en feil under henting av tilhørende kurs");
          connectedCourses.value = [];
        }
      });
    });

    return {
      search,
      headers,
      connectedCourses,
      isVocationalSchool: computed(() => isVocationalSchool(store.state.plans.studyplan.mainCourseId)),
    };
  },
  data: initialData,
});
