








// TODO is this really a base file?
import { mapActions, mapGetters } from "vuex";
import LightboxContentArea from "@/components/shared/LightboxContentArea.vue"; // TODO
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "BaseFileList",
  components: { LightboxContentArea },
  props: {
    curriculumId: {
      type: String,
      required: true,
    },
    entity: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("files", ["getFilelist"]),
  },
  methods: {
    ...mapActions("files", ["fetchFiles", "displayFile"]),
    async openFile(item: any) {
      (this as any).$refs.overlay.overlay = !(this as any).$refs.overlay.overlay;
      this.displayFile(item);
    },
  },
  mounted() {
    const payload = {
      entity: this.entity,
      curriculumId: this.curriculumId,
    };
    this.fetchFiles(payload);
  },
});
