<template>
  <div v-if="chipItems.length > 0">
    <v-chip
      v-for="(chipItem, index) in chipItems"
      :key="index"
      class="ma-2"
      label
      color="primary"
      @click="$emit('clickChip', chipItem)"
    >
      <slot :chipItem="chipItem" />
    </v-chip>
  </div>
  <v-chip v-else class="ma-2" label v-text="noChipsText" />
</template>
<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "BaseChipGroup",
  props: {
    chipItems: {
      type: Array,
      required: true,
    },
    noChipsText: {
      type: [String, Number],
      default: "Ingen elementer",
    },
  },
});
</script>
