

















































import { api } from "@/api/api";
import {
  ApiGetCurriculumDto,
  ApiGetCurriculumFileDto,
  ApiHierarchicalAreaDtoType,
  ApiUpsertCurriculumDto,
} from "@/api/generated/Api";
import CourseList from "@/components/curriculum/details/CourseList.vue";
import CurriculumDetailsCard from "@/components/curriculum/details/CurriculumDetailsCard.vue";
import CurriculumFiles from "@/components/curriculum/details/CurriculumFiles.vue";
import CurriculumHtmlCard from "@/components/curriculum/details/CurriculumHtmlCard.vue";
import BaseChipGroup from "@/components/shared/list/BaseChipGroup.vue";
import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import BaseSheetHeader from "@/components/shared/sheet/BaseSheetHeader.vue";
import CurriculumChipPartnerValue from "@/pages/studieplan/CurriculumChipPartnerValue.vue";
import CurriculumChipTeachingMaterialValue from "@/pages/studieplan/CurriculumChipTeachingMaterialValue.vue";
import { CurriculumRouteNames } from "@/shared/enums/RouteNames/curriculumRouteNamesEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRoute, useRouter } from "@/shared/useHelpers";
import { defineComponent, onMounted, Ref, ref } from "@vue/composition-api";

export default defineComponent({
  name: "StudieplanDetailsPage",
  components: {
    CurriculumDetailsCard,
    CurriculumHtmlCard,
    BaseSheet,
    BaseSheetHeader,
    CourseList,
    CurriculumFiles,
    BaseChipGroup,
    CurriculumChipTeachingMaterialValue,
    CurriculumChipPartnerValue,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const curriculumFileList = ref<ApiGetCurriculumFileDto[]>([]);
    const curriculumValues = ref<ApiGetCurriculumDto | ApiUpsertCurriculumDto>();
    const courseAreas = ref<ApiHierarchicalAreaDtoType[]>();
    const curriculumId = route.params.id;
    const isDraft = route.params.id.includes("-");
    const collapsableTextFields = ref<{ label: string; value?: string | null }[]>([]);

    onMounted(async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await fetchCourseAreas();
        if (isDraft) {
          await fetchDraftAndSetValues();
          return;
        }
        await fetchCurriculumAndSetValues();
      });
    });

    const editCurriculum = () => {
      const mainCourse =
        courseAreas.value &&
        courseAreas.value.find((courseArea) =>
          courseArea.children?.some((child) => child.id === curriculumValues.value?.subCourseId)
        );

      const mainCourseId = mainCourse?.id;

      if (isDraft) {
        router.push({
          path: `/studieplaner/utkast/${route.params.id}`,
          query: { mainCourseId: mainCourseId?.toString() },
        });
        return;
      }
      router.push({
        path: `/studieplaner/studieplan/${route.params.id}`,
        query: { mainCourseId: mainCourseId?.toString() },
      });
    };

    const fetchDraftAndSetValues = async () => {
      const getCurriculumDraftByIdResponse = await api.curriculum.getDraftPerIdAndUserId(curriculumId);
      curriculumValues.value = getCurriculumDraftByIdResponse.data;
      setCollapsableTextFields(curriculumValues, collapsableTextFields);
    };

    const fetchCurriculumAndSetValues = async () => {
      const [getCurriculumByIdResponse, curriculumFileResponse] = await Promise.all([
        api.curriculum.getCurriculumAsync(+curriculumId),
        api.file.getCurriculumFilesAsync(+curriculumId),
      ]);
      curriculumValues.value = getCurriculumByIdResponse.data;
      curriculumFileList.value = curriculumFileResponse.data;

      setCollapsableTextFields(curriculumValues, collapsableTextFields);
    };

    const fetchCourseAreas = async () => {
      const courseAreasFetch = await api.coursearea.getCourseAreasAsync();
      courseAreas.value = courseAreasFetch.data;
    };

    return {
      curriculumValues,
      curriculumFileList,
      collapsableTextFields,
      isDraft,
      isExecutionPlan: route.name === CurriculumRouteNames.CreateExecutionPlanPrerequisites,
      editCurriculum,
      courseAreas,
    };
  },
});

const setCollapsableTextFields = (
  curriculumValues: Ref<ApiGetCurriculumDto | ApiUpsertCurriculumDto | undefined>,
  collapsableTextFields: Ref<{ label: string; value?: string | null }[]>
) => {
  if (!curriculumValues.value) {
    return;
  }
  collapsableTextFields.value = [
    {
      label: "Lærerkrav",
      value: curriculumValues.value.teacherRequirements,
    },
    {
      label: "Forkunnskaper",
      value: curriculumValues.value.foreknowledgeRequirements,
    },
    {
      label: "Målgruppe",
      value: curriculumValues.value.targetGroup,
    },
    {
      label: "Læringsmål",
      value: curriculumValues.value.learningObjectives,
    },
    {
      label: "Innhold",
      value: curriculumValues.value.course?.content,
    },
    {
      label: "Metode",
      value: curriculumValues.value.course?.method,
    },
  ].filter((item) => item.value !== undefined);
};
